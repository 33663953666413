import { Link, useLocation } from "react-router-dom"
import Input from "../../Components/Input"
import InputCheck from "../../Components/InputCheck"
import usePost from "../../Hooks/usePost"
import ButtonLoader from "../../Components/ButtonLoader"
import { Fragment } from "react"
import Api from "../../Functions/Api"
import HandleError from "../../Functions/HandleError"
import Auth from "../../Functions/Auth"

const Register = () => {
    const create = usePost()
    const location = useLocation()
    const register: ButtonLoaderAction = (evt, activedButton) => {
        const form = evt.currentTarget.form as HTMLFormElement

        Api.post("register", form)
            .then((res) => {
                if (!res.ok) return new HandleError(res, form).show()

                console.log('Registrasi Berhasil')
            })
            .finally(() => activedButton())
    }
    return (
        <Fragment>
            {location.hash==="#register"&&<div className="">
            <h1 className="text-center">REGISTER</h1>

            <form>
                <Input id="name" label="Nama" name="name" />
                <Input id="birthplace" label="Tempat Lahir" name="birthplace" />
                <Input
                    id="date_of_birth"
                    type="date"
                    label="Tanggal Lahir"
                    name="date_of_birth"
                    defaultValue="2000-01-01"
                />
                <Input
                    id="general_education"
                    label="Pendidikan Umum"
                    name="general_education"
                />
                <Input
                    id="special_education"
                    label="Pendidikan Khusus"
                    name="special_education"
                />
                <div>
                    <label className="col-md-12">Jenis Kelamin</label>

                    <div className="d-flex gap-3">
                        <InputCheck
                            id="laki-laki"
                            label="Laki-laki"
                            name="gender"
                            type="radio"
                            value={"L"}
                        />
                        <InputCheck
                            id="perempuan"
                            label="Perempuan"
                            name="gender"
                            type="radio"
                            value={"P"}
                        />
                    </div>
                </div>
                <Input id="username-reg" label="Username" name="username" />
                <Input id="institute" label="institute" name="institute" type="hidden" value="-" />
                <Input
                    id="password-reg"
                    type="password"
                    label="Password"
                    name="password"
                />
                <p>
                    Sudah memiliki akun? <Link to="#login">Sign In sekarang</Link>
                </p>

                <ButtonLoader action={register} text="Register" />
            </form>
        </div>}
        </Fragment>
        
    )
}

export default Register
